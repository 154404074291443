
function loadMapScenario() {
	const mapBox = document.getElementById('map')
	let map = new Microsoft.Maps.Map(mapBox, {})
	let lat = 52.476982,
		lng = -1.801498
	map.setView({
		zoom: 17,
		center: new Microsoft.Maps.Location(lat, lng),
	})
	map.setOptions({
		maxZoom: 19,
		minZoom: 12,
	})

	let center = map.getCenter();
	let pin = new Microsoft.Maps.Pushpin(center, {
		title: 'Abbey Court',
		subTitle: 'Care Home',
		text: 'A',
		color: '#c4000a'
	});

	//Add the pushpin to the map
	map.entities.push(pin);
}