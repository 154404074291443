function updateHeader(area) {
	let fixedArea = area.getBoundingClientRect().height
	document.documentElement.style.setProperty('--headerHeight', fixedArea + 'px')
}

function footerHeight() {
	const footer = document.querySelector('footer')
	let footerArea = footer.getBoundingClientRect().height
	document.documentElement.style.setProperty('--footerHeight', footerArea + 'px')
}

{
	const fixed = document.querySelector('.fixed')
	fixed.classList.add('affix')
	updateHeader(fixed)
	footerHeight()

	window.addEventListener('resize', () => {
		updateHeader(fixed)
		footerHeight()
	})	
}